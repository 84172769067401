var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('sw-table',{attrs:{"table-id":"d7c12e473e7843b8be04857e2a2bac30","pagination":_vm.pagination},on:{"change-pagination":function($event){return Object.assign(_vm.pagination, $event)},"reload-content":_vm.loadReports},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{staticClass:"mb-0",attrs:{"striped":"","responsive":"","hover":"","items":_vm.items,"fields":_vm.fields,"per-page":_vm.pagination.perPage,"busy":_vm.isLoading},on:{"reload-content":_vm.loadReports},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"cell(totalTokens)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"size":"sm","variant":"danger"}},[_vm._v(" "+_vm._s(("" + (value || 0)))+" ")])]}},{key:"cell(content)",fn:function(ref){
var index = ref.index;
return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],attrs:{"size":"sm","variant":"flat-primary","title":_vm.$t('Preview')},on:{"click":function($event){return _vm.showPreview(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"EyeIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Preview'))+" ")],1)]}},{key:"cell(source)",fn:function(ref){
var value = ref.value;
return [(value.length)?_c('b-badge',{attrs:{"size":"sm","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t(("ai.types." + value)))+" ")]):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(clientChat)",fn:function(ref){
var item = ref.item;
return [(item.clientChat)?[_c('b-link',{attrs:{"to":{ name: 'client-chat', params: { openConversationId: item.clientChat.id } }}},[_c('feather-icon',{attrs:{"icon":"ExternalLinkIcon"}})],1)]:_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(process)",fn:function(ref){
var item = ref.item;
return [(item.contactThread)?[_c('router-link',{class:{ 'deleted-text': item.contactThread.contact.deletedAt },attrs:{"to":("/contact/" + (item.contactThread.contact.id) + "/thread/" + (item.contactThread.id) + "/emails")}},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.getUserName(item.contactThread.contact),30))+" - "+_vm._s(_vm._f("truncate")(item.contactThread.name,30))+" ")])]:(item.clientChat && item.clientChat.contactThreads && Array.isArray(item.clientChat.contactThreads) && item.clientChat.contactThreads.length)?_vm._l((item.clientChat.contactThreads),function(threadItem,index){return _c('div',{key:("thread_" + index)},[_c('router-link',{class:{ 'deleted-text': threadItem.contact.deletedAt },attrs:{"to":("/contact/" + (threadItem.contact.id) + "/thread/" + (threadItem.id) + "/timeline")}},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.getUserName(threadItem.contact),30))+" - "+_vm._s(_vm._f("truncate")(threadItem.name,30))+" ")])],1)}):_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
return [(value)?[_vm._v(" "+_vm._s(value)+" ")]:_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(version)",fn:function(ref){
var value = ref.value;
return [(value)?[_vm._v(" "+_vm._s(value)+" ")]:_c('feather-icon',{attrs:{"icon":"MinusIcon"}})]}},{key:"cell(createdBy)",fn:function(ref){
var value = ref.value;
return [_c('avatar',{attrs:{"user":value,"to":{ name: 'user', params: { userId: value.id } }}})]}},{key:"cell(createdAt)",fn:function(data){return [_vm._v(" "+_vm._s(data.value.date.split('.')[0])+" ")]}},{key:"cell(actions)",fn:function(ref){
var index = ref.index;
return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-danger",modifiers:{"hover":true,"v-danger":true}}],staticClass:"btn-icon",attrs:{"size":"sm","variant":"flat-danger","title":_vm.$t('Cancel')},on:{"click":function($event){return _vm.cancel(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"TrashIcon"}})],1)]}}])})]},proxy:true}])})],1),_c('b-modal',{attrs:{"title":_vm.$t('Preview'),"size":"xl","static":"","hide-footer":""},model:{value:(_vm.isShowPreview),callback:function ($$v) {_vm.isShowPreview=$$v},expression:"isShowPreview"}},[_c('div',{staticClass:"border rounded-lg px-2 py-1"},[(_vm.previewContent.resultText)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getContent(_vm.previewContent.resultText, _vm.previewContent.version))}}):_c('div',{staticClass:"text-center py-50 font-weight-bold"},[_c('feather-icon',{attrs:{"size":"17","icon":"XCircleIcon"}}),_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }