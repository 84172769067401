<template>
  <div>
    <b-card>
      <sw-table
        table-id="d7c12e473e7843b8be04857e2a2bac30"
        :pagination="pagination"
        @change-pagination="Object.assign(pagination, $event)"
        @reload-content="loadReports"
      >
        <template #table>
          <b-table
            striped
            responsive
            hover
            :items="items"
            :fields="fields"
            :per-page="pagination.perPage"
            class="mb-0"
            :busy="isLoading"
            @reload-content="loadReports"
          >
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>

            <template #cell(totalTokens)="{ value }">
              <b-badge
                size="sm"
                variant="danger"
              >
                <!--                {{ `${value} ${$t('ForPrice')} ${pricePerToken | priceFormat } PLN` }}-->
                {{ `${value || 0}` }}
              </b-badge>
            </template>

            <template #cell(content)="{ index }">
              <b-button
                v-b-tooltip.hover.v-primary
                size="sm"
                variant="flat-primary"
                :title="$t('Preview')"
                @click="showPreview(index)"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-25"
                />

                {{ $t('Preview') }}
              </b-button>
            </template>

            <template #cell(source)="{ value }">
              <b-badge
                v-if="value.length"
                size="sm"
                variant="primary"
              >
                {{ $t(`ai.types.${value}`) }}
              </b-badge>

              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <template #cell(clientChat)="{ item }">
              <template v-if="item.clientChat">
                <b-link :to="{ name: 'client-chat', params: { openConversationId: item.clientChat.id } }">
                  <feather-icon icon="ExternalLinkIcon" />
                </b-link>
              </template>

              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <template #cell(process)="{ item }">
              <template v-if="item.contactThread">
                <router-link :class="{ 'deleted-text': item.contactThread.contact.deletedAt }"
                             :to="`/contact/${item.contactThread.contact.id}/thread/${item.contactThread.id}/emails`"
                >
                  {{ getUserName(item.contactThread.contact) | truncate(30) }} - {{ item.contactThread.name | truncate(30) }}
                </router-link>
              </template>

              <template v-else-if="item.clientChat && item.clientChat.contactThreads && Array.isArray(item.clientChat.contactThreads) && item.clientChat.contactThreads.length">
                <div
                  v-for="(threadItem, index) in item.clientChat.contactThreads"
                  :key="`thread_${index}`"
                >
                  <router-link
                    :class="{ 'deleted-text': threadItem.contact.deletedAt }"
                    :to="`/contact/${threadItem.contact.id}/thread/${threadItem.id}/timeline`"
                  >
                    {{ getUserName(threadItem.contact) | truncate(30) }} - {{ threadItem.name | truncate(30) }}
                  </router-link>
                </div>
              </template>

              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <template #cell(status)="{ value }">
              <template v-if="value">
                {{ value }}
              </template>

              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <template #cell(version)="{ value }">
              <template v-if="value">
                {{ value }}
              </template>

              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <template #cell(createdBy)="{ value }">
              <avatar
                :user="value"
                :to="{ name: 'user', params: { userId: value.id } }"
              />
            </template>

            <template #cell(createdAt)="data">
              {{ data.value.date.split('.')[0] }}
            </template>

            <template #cell(actions)="{ index }">
              <b-button
                v-b-tooltip.hover.v-danger
                size="sm"
                variant="flat-danger"
                :title="$t('Cancel')"
                class="btn-icon"
                @click="cancel(index)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="mr-25"
                />
              </b-button>
            </template>
          </b-table>
        </template>
      </sw-table>
    </b-card>

    <b-modal
      v-model="isShowPreview"
      :title="$t('Preview')"
      size="xl"
      static
      hide-footer
    >
      <div class="border rounded-lg px-2 py-1">
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="previewContent.resultText"
          v-html="getContent(previewContent.resultText, previewContent.version)"
        />

        <div
          v-else
          class="text-center py-50 font-weight-bold"
        >
          <feather-icon
            size="17"
            icon="XCircleIcon"
          />

          {{ $t('NoData') }}
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { GET_OPEN_AI_LOG_ALL } from '@/@constants/mutations'
import { priceFormat } from '../../../@core/filters/parts/valueFormat'

export default {
  components: {
    BTable,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    isLoading: false,

    isShowPreview: false,
    previewContent: '',
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 100,
    },

    fields: [
      {
        key: 'content',
        label: 'ai.reports.Message',
      },
      {
        key: 'source',
        label: 'ai.reports.Type',
      },
      {
        key: 'totalTokens',
        label: 'ai.reports.Tokens',
      },
      {
        key: 'process',
        label: 'ai.reports.Process',
      },
      {
        key: 'clientChat',
        label: 'ClientChat',
      },
      // {
      //   key: 'status',
      //   label: 'Status',
      // },
      {
        key: 'version',
        label: 'ai.reports.Version',
      },
      {
        key: 'createdBy',
        label: 'ai.reports.CreatedBy',
      },
      {
        key: 'createdAt',
        label: 'ai.reports.CreatedAt',
      },
    ],

    TYPE_LIST: {
      EMAIL: 'EMAIL',
      SMS: 'SMS',
    },

    pricePerToken: 100.00,

    items: [],
  }),

  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
    }),
  },

  mounted() {
    this.ini()
  },

  methods: {
    priceFormat,
    ini() {
      this.loadReports()
    },

    loadReports() {
      this.isLoading = true

      const payload = {
        pagination: this.pagination,
      }

      this.$store.dispatch(`ai/${GET_OPEN_AI_LOG_ALL}`, payload)
        .then(res => {
          this.items = res.data.items
          this.pagination.totalRecords = res.data.totalItemCount
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    showPreview(index) {
      const item = this.items[index]

      if (item) {
        this.isShowPreview = true
        this.previewContent = {
          resultText: item.resultText || item.result?.content || '',
          message: item.message,
          reportsType: item.reportsType,
        }
      }
    },

    getStatusVariant(status) {
      const { STATUS_LIST } = this

      switch (status) {
        case STATUS_LIST.ACCEPTED:
          return 'success'

        case STATUS_LIST.REJECTED:
          return 'danger'

        default:
          return 'primary'
      }
    },

    getContent(result, version) {
      switch (version) {
        case '3.5': return JSON.parse(result)[2].content

        default: return result
      }
    },

    cancel() {
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
    },
  },
}
</script>
